import React, { useState, Fragment, useCallback } from "react";
// useEffect, useRef, useContext
import "./App.css";
import mountains from "./assets/images/design1.png";
import logo from "./assets/images/logo.png";
import picture from "./assets/images/picture.png";
import divider1 from "./assets/images/divider1.png";
import divider2 from "./assets/images/divider2.png";
import color from "./assets/images/color.png";
import attire from "./assets/images/attire.png";
import Snowfall from "react-snowfall";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImageViewer from "react-simple-image-viewer";
// -----------------------------------
import picture1 from "./assets/images/photos/photo(1).png";
import picture2 from "./assets/images/photos/photo(2).png";
import picture3 from "./assets/images/photos/photo(3).png";
import picture4 from "./assets/images/photos/photo(4).png";
import picture5 from "./assets/images/photos/photo(5).png";
import picture6 from "./assets/images/photos/photo(6).png";
import picture7 from "./assets/images/photos/photo(7).png";
import picture8 from "./assets/images/photos/photo(8).png";
import picture9 from "./assets/images/photos/photo(9).png";
import picture10 from "./assets/images/photos/photo(10).png";
import picture11 from "./assets/images/photos/photo(11).png";
import picture12 from "./assets/images/photos/photo(12).png";
import picture13 from "./assets/images/photos/photo(13).png";
import picture14 from "./assets/images/photos/photo(14).png";
import picture15 from "./assets/images/photos/photo(15).png";
import picture16 from "./assets/images/photos/photo(16).png";
import picture17 from "./assets/images/photos/photo(17).png";
import picture18 from "./assets/images/photos/photo(18).png";
import picture19 from "./assets/images/photos/photo(19).png";
// -----------------------------------
import { Dialog, Transition } from "@headlessui/react";
function App() {
  const petal1 = document.createElement("img");
  petal1.src = require("./assets/images/leaf_a.png");
  const petal2 = document.createElement("img");
  petal2.src = require("./assets/images/leaf_b.png");
  const petal3 = document.createElement("img");
  petal3.src = require("./assets/images/leaf_c.png");
  const petal4 = document.createElement("img");
  petal4.src = require("./assets/images/leaf_d.png");
  const images = [petal1, petal2, petal3, petal4];
  const defaultProps = {
    center: {
      lat: 59.93804,
      lng: 30.337157,
    },
    zoom: 5,
  };
  // -----------------
  const [isOpen, setIsOpen] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  // -----------------
  const album = [
    picture1,
    picture2,
    picture3,
    picture4,
    picture5,
    picture6,
    picture7,
    picture8,
    picture9,
    picture10,
    picture11,
    picture12,
    picture13,
    picture14,
    picture15,
    picture16,
    picture17,
    picture18,
    picture19,
  ];
  // -----------------
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <div className="default-bgcolor-1">
        <Transition
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Dialog onClose={() => setIsOpen(false)}>
            <Dialog.Panel>
              <Dialog.Title>Deactivate account</Dialog.Title>
              {/* ... */}
            </Dialog.Panel>
          </Dialog>
        </Transition>

        <div className="p-3 mx-auto sm:py-6 lg:py-20 max-w-7xl">
          <Snowfall
            radius={[20.0, 40.0]}
            snowflakeCount={10}
            images={images}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
            }}
          />
          <div className="p-6 shadow-lg default-bgimage-1">
            <div>
              <img
                src={mountains}
                className="h-auto max-w-full px-6 mx-auto "
                width="300"
                alt="img name"
              />
              <div className="grid grid-cols-2 gap-4 xl:p-11 p-0">
                <div className="col-span-2 xl:col-span-1">
                  <img
                    src={logo}
                    className="h-auto max-w-full px-10 mx-auto pt-10"
                    width="350"
                    alt="img name"
                  />
                  <h1 className="text-center text-2xl default-color-1 tracking-[7px] font-georgia">
                    SAVE THE DATE
                  </h1>
                  <div className="grid items-center grid-cols-8 gap-4 py-10">
                    <div className="col-span-8">
                      <h1 className="text-center text-3xl default-color-1 font-georgia tracking-[7px]">
                        JUNE
                      </h1>
                    </div>
                    <div className="col-span-8 md:col-span-3">
                      <div className="py-4 box-bordered">
                        <h1 className="md:text-right text-center text-2xl default-color-1 font-georgia tracking-[4px]">
                          SATURDAY
                        </h1>
                      </div>
                    </div>
                    <div className="col-span-8 md:col-span-2">
                      <h1 className="text-center text-7xl default-color-1 font-georgia">
                        01
                      </h1>
                    </div>
                    <div className="col-span-8 md:col-span-3">
                      <div className="py-4 box-bordered">
                        <h1 className="md:text-left text-center text-2xl default-color-1 font-georgia tracking-[4px]">
                          AT 2:00PM
                        </h1>
                      </div>
                    </div>
                    <div className="col-span-8">
                      <h1 className="text-center text-3xl default-color-1 font-georgia tracking-[7px]">
                        2024
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1">
                  <img
                    src={picture}
                    className="h-auto max-w-full mx-auto"
                    width="500"
                    alt="img name"
                  />
                </div>
              </div>
              <img
                src={divider2}
                className="h-auto max-w-full px-6 mx-auto "
                alt="img name"
              />
              <div className="my-10">
                {isViewerOpen && (
                  <ImageViewer
                    src={album}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  // removeArrowOnDeviceType={["", ""]}
                  arrows={false}
                >
                  {album.map((item, index) => {
                    return (
                      <div onClick={() => openImageViewer(index)}>
                        <img
                          src={item}
                          className="object-cover h-48 w-96 px-2"
                          alt="img name"
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <img
                src={divider1}
                className="h-auto max-w-full px-6 mx-auto "
                alt="img name"
              />
              <div className="py-12">
                <h1 className="text-center text-3xl default-color-1 tracking-[7px] font-georgia">
                  YOU ARE INVITED
                </h1>
                <div className="grid items-center grid-cols-3 gap-20 p-10">
                  <div className="col-span-3 lg:col-span-1">
                    <h1 className="text-center text-xl default-color-1 font-georgiabold tracking-[7px]">
                      WHEN
                    </h1>
                    <div className="py-5">
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        JUNE 01, 2024
                        <br />
                        2:00 PM ONWARDS
                      </p>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <h1 className="text-center text-xl default-color-1 font-georgiabold tracking-[7px]">
                      WHERE
                    </h1>
                    <div className="py-5">
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Our Lady of Mt. Carmel Parish Church Sabang, Baliuag,
                        Bulacan
                      </p>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <h1 className="text-center text-xl default-color-1 font-georgiabold tracking-[7px]">
                      RECEPTION
                    </h1>
                    <div className="py-5">
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Villa Rosalinda Resort
                        <br />
                        Bustos Bulacan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={divider2}
                className="h-auto max-w-full px-6 mx-auto "
                alt="img name"
              />
              <div className="py-12">
                {/* -------------------------- */}
                <h1 className="text-4xl text-center default-color-1 font-commercial">
                  Our beloved family
                </h1>
                <div className="grid grid-cols-2 gap-20 p-5">
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Alvin M. Reyes
                      </h3>
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Father
                      </p>
                    </div>
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mrs. Leonila C. Reyes
                      </h3>
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Mother
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Victor E. Benitez
                      </h3>
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Brother
                      </p>
                    </div>
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mrs. Linda E. Benitez
                      </h3>
                      <p className="text-xl text-center default-color-1 font-georgia ">
                        Mother
                      </p>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <h1 className="text-4xl text-center default-color-1 font-commercial">
                  Principal Sponsors
                </h1>
                <div className="grid grid-cols-2 gap-20 p-5">
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Rodrigo Reyes Jr.
                        <br />
                        Mr. Angelito Sarmiento
                        <br />
                        Mr. Jay Albert Soriano
                        <br />
                        Mr. Rodolfo Bayani Jr.
                        <br />
                        Mr. Rufino Carlos Jr.
                        <br />
                        Mr. Mario Comia
                        <br />
                        Mr. Lorenzo Orosco
                        <br />
                        Mr. Hermogenes Guanzing
                        <br />
                        Mr. Edwin Reyes
                        <br />
                        Mr. Edmundo Gonzales
                        <br />
                        Mr. Andres Pe Chua
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mrs. Jennifer Pile
                        <br />
                        Mrs. Estefany Agapito
                        <br />
                        Mrs. Jaim Mari Fernandez
                        <br />
                        Mrs. Nena Famocol
                        <br />
                        Mrs. Aida Aloc
                        <br />
                        Mrs. Teresita Lantaco
                        <br />
                        Mrs. Priscilla Cruz
                        <br />
                        Mrs. Lourdes Hingpit
                        <br />
                        Mrs. Anabelle Bunquin
                        <br />
                        Mrs. Mayrizza Mercado
                        <br />
                        Mrs. Olivia Figueroa Diaz
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <div className="grid grid-cols-2 gap-20 p-5">
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Best Man
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Joshua Angelo Tolosa
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Maid of Honor
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Ms. Ma. Socorro Queenberly Reyes
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <div className="grid grid-cols-2 gap-20 p-5">
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Groomsmen
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Eduardo Aduna Jr.
                        <br />
                        Mr. Wayne Cabrera
                        <br />
                        Mr. Gian Fulgueras
                        <br />
                        Mr. Danel Acuzar Fernandez
                        <br />
                        Mr. Angelo Bueno
                        <br />
                        Mr. Mark Mendoza
                        <br />
                        Mr. Louie Guballa 
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Bridesmaids
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Ms. Ma. Lourdes Reyes
                        <br />
                        Ms. Princess Erica Angeles
                        <br />
                        Ms. Angel Mari Reyes
                        <br />
                        Ms. Jessabel Reyes
                        <br />
                        Ms. Marichrist Legaspi
                        <br />
                        Ms. Jessamae Cruz
                        <br />
                        Ms. Jennilyn Calizon
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <h1 className="text-4xl text-center default-color-1 font-commercial">
                  Secondary Sponsors
                </h1>
                <div className="grid grid-cols-3 gap-5 p-5">
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Candle
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Jose Nathaniel Reyes
                        <br />
                        Ms. Lei Ashley Sarmiento
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Veil
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Calen Ragas
                        <br />
                        Ms. Zenaida Organis
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Cord
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mr. Sylvio Reyes
                        <br />
                        Ms. Aira Jane Alberto
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <div className="grid grid-cols-3 gap-5 p-5">
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Ring Bearer
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Rodrigo Reyes III
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Bible Bearer
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Bryll Sebastian Mago
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Coin Bearer
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Prince Charlie Carmona
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <div className="grid grid-cols-3 gap-5 p-5">
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Flower Girl
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Mary Margaret Harris
                        <br />
                        Marieta Beatriz Mago
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Junior Groomsmen
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Prince Dalmore Reyes
                        <br />
                        Brayden Eli Cruz
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Junior Bridesmaids
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Aviegil De Guzman
                        <br />
                        LJ Kealey Fulgueras
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
                {/* -------------------------- */}
                <div className="grid grid-cols-2 gap-5 p-5">
                  <div className="col-span-2 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Little Groom
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Jhon Zhardius Jacob Dasco
                      </h3>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <div className="py-3">
                      <h3 className="mb-3 text-3xl text-center default-color-1 font-commercial">
                        Little Bride
                      </h3>
                      <h3 className="text-2xl text-center default-color-1 font-georgia ">
                        Erin Cassiopeia Harris
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={divider1}
                className="h-auto max-w-full px-6 mx-auto "
                alt="img name"
              />
              <div className="py-12">
                <h1 className="text-4xl text-center default-color-1 font-commercial">
                  Honeymoon Wish
                </h1>
                <div className="grid grid-cols-6 gap-5 p-5">
                  <div className="col-span-6 lg:col-span-1"></div>
                  <div className="col-span-6 lg:col-span-4">
                    <div className="p-7">
                      <p className="text-2xl text-center default-color-1 font-georgia ">
                        When thinking of a gift for us, we ask if you'd please
                        consider contributing to our honeymoon, to make it all
                        the sweeter. Our house is full of all the things a
                        couple could require, and so a holiday away is what we
                        most desire. Then while we're relaxing on the beach or
                        by the pool so blue we'll sit back and know that it is
                        truly thanks to you!
                      </p>
                    </div>
                  </div>
                  <div className="col-span-6 lg:col-span-1"></div>
                </div>
              </div>
              <img
                src={divider2}
                className="h-auto max-w-full px-6 mx-auto "
                alt="img name"
              />
              <div className="grid items-center grid-cols-2 gap-4 p-11 ">
                <div className="col-span-2 xl:col-span-1">
                  <h1 className="text-4xl text-center default-color-1 font-commercial">
                    Color Guide
                  </h1>
                  <div className="py-12">
                    <img
                      src={color}
                      className="h-auto max-w-full px-10 mx-auto "
                      width="350"
                      alt="img name"
                    />
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1">
                  <h1 className="text-4xl text-center default-color-1 font-commercial">
                    Attire
                  </h1>
                  <div className="py-12">
                    <img
                      src={attire}
                      className="h-auto max-w-full mx-auto "
                      width="500"
                      alt="img name"
                    />
                  </div>
                </div>
              </div>
              <div className="py-12">
                <h1 className="text-4xl text-center default-color-1 font-commercial">
                  Snaps
                </h1>
                <div className="grid grid-cols-6 gap-5 p-5">
                  <div className="col-span-6 lg:col-span-1"></div>
                  <div className="col-span-6 lg:col-span-4">
                    <div className="p-3">
                      <p className="text-2xl text-center default-color-1 font-georgia ">
                        Help us capture the love!
                        <br />
                        Please tag your post to help us document
                        <br />
                        our special day using the hashtag:
                      </p>
                    </div>
                  </div>
                  <div className="col-span-6 lg:col-span-1"></div>
                </div>
                <h1 className="text-3xl text-center default-color-1 font-georgia ">
                  #YNAgotconVINCEd
                </h1>
              </div>
              <img
                src={mountains}
                className="h-auto max-w-full px-6 mx-auto "
                width="300"
                alt="img name"
              />
              <div className="py-10">
                <iframe
                  width="100%"
                  height="400"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d7709.1820999912!2d120.90690699508573!3d14.959860257510714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3396555a6ed9346d%3A0xa0534e350addfea7!2sOur%20Lady%20of%20Mt.%20Carmel%20Parish%20Church%20-%20Sabang%2C%20Baliuag%2C%20Bulacan%20(Diocese%20of%20Malolos)%2C%20XW89%2BM2R%2C%20Baliwag%2C%20Bulacan!3m2!1d14.9667482!2d120.9175301!4m5!1s0x3397007537b8f43b%3A0xcc5cd119445b383b!2sVilla%20Rosalinda%20Resort%2C%20Bustos%2C%20Bulacan!3m2!1d14.9526369!2d120.919181!5e0!3m2!1sen!2sph!4v1697203284750!5m2!1sen!2sph"
                ></iframe>
              </div>
              <img
                src={mountains}
                className="h-auto max-w-full px-6 mx-auto "
                width="300"
                alt="img name"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default App;
